.catalog-modal-root {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .catalog-modal-root.guest-catalog-modal-root {
    width: 100%;
  }
}
.catalog-modal-root #catalog-modal-wrapper {
  width: 95%;
  height: 95%;
  background-color: #e5eaef;
  border-radius: 3px;
}
.catalog-modal-root #catalog-modal-wrapper.ne-catalog-modal-wrapper {
  overflow-y: auto;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper {
  height: 6%;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper.catalog-new-wrapper {
  flex-direction: column;
  margin-bottom: 20px;
  align-items: unset;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper.catalog-new-wrapper .catalog-new-close-icon {
  text-align: right;
  padding-bottom: 20px;
  margin-right: 30px;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper.catalog-new-wrapper .catalog-new-close-icon i {
  font-size: 20px;
  color: #424c9f;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper.catalog-new-wrapper .catalog-new-title-search-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper.catalog-new-wrapper .catalog-new-title-search-wrapper.guest-title-search-wrapper {
    margin-right: 2.5%;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .title {
  width: 170px;
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: #6471de;
  padding-left: 30px;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .title.ne-title {
    width: 150px;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .title p {
  height: 40px;
  width: 135px;
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .search-filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .search-filter-wrapper .cancel-icon {
  font-size: 18px;
  padding: 0 30px 0 20px;
  color: #424c9f;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .search-filter-wrapper .badge {
  background-color: #4a90e2;
  color: #ffffff;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .search-filter-wrapper .icon-root {
  margin-right: 35px;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-title-wrapper .search-filter-wrapper .icon-root {
    margin-right: 15px;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper {
  padding: 0 30px 10px 30px;
  height: 74%;
  overflow-y: auto;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper.guest-catalog-modal-content-wrapper {
  padding: 0 !important;
  width: 95% !important;
  margin: 0 auto !important;
  height: calc(78% - 52px) !important;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper.guest-ne-catalog-modal-content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  overflow-y: unset;
  height: unset !important;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper {
  border: 0.5px solid #686868;
  border-radius: 3px;
  padding: 5px 10px;
  margin-bottom: 5px;
  background-color: #ffffff;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper.ne-catalog-item-wrapper {
  width: 49.5%;
  height: 110px;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper.ne-catalog-item-wrapper {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper.ne-catalog-item-wrapper {
    width: 100%;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper {
  height: 80px;
  width: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper.ne-catalog-modal-item-wrapper {
  height: 100px;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label {
  width: 120px;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label {
    width: 105px;
    justify-content: center;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label {
    width: 105px;
    justify-content: center;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label .pdt-img {
  width: 100px;
  height: 100px;
  object-fit: fill;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label .pdt-img {
    width: 90px;
    height: 90px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label .pdt-img {
    width: 90px;
    height: 90px;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label .pdt-icon {
  font-size: 100px;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label .pdt-icon {
    font-size: 90px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label.ne-catalog-modal-item-label .pdt-icon {
    font-size: 90px;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label p {
  font-size: 12px;
  color: #989898;
  margin-bottom: 0;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-wrapper .catalog-modal-item-label p:nth-child(2) {
  font-weight: bold;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .desc-wrapper {
  background: #ffffff;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .desc-wrapper .select-desc-wrapper {
  width: 130px;
  height: 35px;
  background-color: #f5f5f5;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .desc-wrapper .select-desc-wrapper .select-desc-item {
  width: 48%;
  height: 90%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .desc-wrapper .select-desc-wrapper .select-desc-item p {
  font-size: 12px;
  margin: 0;
  color: #b1b1b1;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .desc-wrapper .select-desc-wrapper .select-desc-item p.selected {
  color: #ffffff;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .desc-wrapper .select-desc-wrapper .select-desc-item.selected {
  background-color: #2ac940;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty {
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty.ne-catalog-modal-item-qty {
  width: calc(100% - 120px) !important;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty.ne-catalog-modal-item-qty {
    width: calc(100% - 105px) !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty.ne-catalog-modal-item-qty {
    width: calc(100% - 105px) !important;
  }
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty.ne-catalog-modal-item-qty .pdt-price-input-wrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .catalog-qty-input {
  height: 35px;
  width: 80px;
  font-size: 14px;
  border: 1px solid #d6d7da;
  border-radius: 3px;
  padding: 5px;
  background-color: #e5eaef;
  color: #989898;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .catalog-qty-input.ne-catalog-qty-input {
  font-size: 16px;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .catalog-qty-input input {
  height: 35px;
  padding: 0;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .catalog-qty-input::before {
  border-bottom: none;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .catalog-qty-input::after {
  border-bottom: none;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .catalog-qty-input.err-qty {
  border-color: red;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .ne-pdt-details {
  font-size: 16px;
  color: black;
  margin-bottom: 0;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .ne-ellipsis {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  line-height: 18px;
  height: 36px;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .ne-ellipsis-pc {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  line-height: 30px;
  height: 20px;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty p {
  font-size: 12px;
  color: red;
  margin: 0;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty.catalog-modal-item-input {
  flex-direction: column;
  align-items: flex-end;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .disc-wrapper {
  font-size: 12px;
  color: #989898;
  cursor: pointer;
  padding-top: 5px;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-item-wrapper .catalog-modal-item-qty .disc-wrapper .disc-tax-down-icon {
  padding-left: 5px;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .catalog-data-spinner {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .add-more-wrapper.ne-add-more-wrapper {
  width: 100%;
}
.catalog-modal-root #catalog-modal-wrapper .catalog-modal-content-wrapper .add-more-wrapper p {
  margin-bottom: 0;
  text-align: center;
  color: #3399ff;
  font-size: 16px;
  padding: 5px 0;
  cursor: pointer;
}
.catalog-modal-root #catalog-modal-wrapper .buttons-wrapper {
  width: 100%;
  height: 6%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.catalog-modal-root #catalog-modal-wrapper .buttons-wrapper .form-create-button {
  height: 40px;
  width: 100px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: #00acf1;
  color: #ffffff;
  text-transform: capitalize;
}
.catalog-modal-root #catalog-modal-wrapper .buttons-wrapper .form-create-button:focus {
  outline: none;
}
.catalog-modal-root #catalog-modal-wrapper .buttons-wrapper .form-create-button:hover {
  background-color: #00acf1;
  color: #ffffff;
}

.summary-view-wrapper {
  width: 100%;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 20px 0 20px;
  border-radius: 3px;
}
.summary-view-wrapper.ne-summary-view-wrapper {
  padding: 10px 20px 0 20px;
}
.summary-view-wrapper p {
  font-size: 12px;
  color: #989898;
  margin-bottom: 0;
}
.summary-view-wrapper p.ne-p {
  font-size: 16px;
}
.summary-view-wrapper .price-wrapper {
  display: flex;
  flex-direction: row;
}
.summary-view-wrapper .price-wrapper p {
  font-weight: bold;
}
.summary-view-wrapper .price-wrapper i {
  font-size: 18px;
  color: #fa9917;
}

