@import './../../../scss/mixins';

.user-id-card-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  h2 {
    padding: 30px 0;
    @include smdevice {
      font-size: 25px;
    }
  }
  p {
    color: red;
  }
  .company-logo {
    padding: 40px 0;
    img {
      width: 200px;
      @include desktop {
        width: 250px;
      }
    }
  }
}
