@import './../../../../scss/mixins';

.catalog-modal-root {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.guest-catalog-modal-root {
    @include smdevice {
      width: 100%;
    }
  }

  #catalog-modal-wrapper {
    width: 95%;
    // min-height: 90%;
    // max-height: 95%;
    height: 95%;
    // overflow-y: auto;
    background-color: #e5eaef;
    border-radius: 3px;
    &.ne-catalog-modal-wrapper {
      overflow-y: auto;
    }
    .catalog-modal-title-wrapper {
      // height: 40px;
      height: 6%;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.catalog-new-wrapper {
        flex-direction: column;
        margin-bottom: 20px;
        align-items: unset;

        .catalog-new-close-icon {
          text-align: right;
          padding-bottom: 20px;
          margin-right: 30px;

          i {
            font-size: 20px;
            color: #424c9f;
          }
        }

        .catalog-new-title-search-wrapper {
          display: flex;
          justify-content: space-between;
          margin-right: 30px;

          &.guest-title-search-wrapper {
            @include smdevice {
              margin-right: 2.5%;
            }
          }
        }
      }

      .title {
        width: 170px;
        height: 40px;
        border-radius: 0 20px 20px 0;
        background-color: #6471de;
        padding-left: 30px;
        &.ne-title {
          @include smdevice {
            width: 150px;
          }
        }
        p {
          // width: 160px;
          height: 40px;
          width: 135px;
          font-size: 24px;
          color: #ffffff;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .search-filter-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .cancel-icon {
          font-size: 18px;
          padding: 0 30px 0 20px;
          color: #424c9f;
        }
        .badge {
          background-color: #4a90e2;
          color: #ffffff
        }
        .icon-root {
          margin-right: 35px;
          @include smdevice {
            margin-right:15px;
          }
        }
      }
    }

    .catalog-modal-content-wrapper {
      padding: 0 30px 10px 30px;
      height: 74%;
      overflow-y: auto;

      &.guest-catalog-modal-content-wrapper {
        padding: 0 !important;
        width: 95% !important;
        margin: 0 auto !important;
        height: calc(78% - 52px) !important;
      }

      &.guest-ne-catalog-modal-content-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        overflow-y: unset;
        height: unset !important;
      }

      .catalog-item-wrapper {
        border: 0.5px solid #686868;
        border-radius: 3px;
        padding: 5px 10px;
        margin-bottom: 5px;
        background-color: #ffffff;

        &.ne-catalog-item-wrapper {
          width: 49.5%;
          height: 110px;

          @include smdevice {
            width: 100%;
          }

          @include tablet {
            width: 100%;
          }
        }

        .catalog-modal-item-wrapper {
          height: 80px;
          width: 100%;
          border-radius: 3px;
          display: flex;
          flex-direction: row;

          &.ne-catalog-modal-item-wrapper {
            height: 100px;
          }

          .catalog-modal-item-label {
            height: 100%;
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.ne-catalog-modal-item-label {
              width: 120px;

              @include smdevice {
                width: 105px;
                justify-content: center;
              }

              @include tablet {
                width: 105px;
                justify-content: center;
              }

              .pdt-img {
                width: 100px;
                height: 100px;
                object-fit: fill;

                @include smdevice {
                  width: 90px;
                  height: 90px;
                }

                @include tablet {
                  width: 90px;
                  height: 90px;
                }
              }

              .pdt-icon {
                font-size: 100px;

                @include smdevice {
                  font-size: 90px;
                }

                @include tablet {
                  font-size: 90px;
                }
              }
            }

            p {
              font-size: 12px;
              color: #989898;
              margin-bottom: 0;

              &:nth-child(2) {
                font-weight: bold;
              }
            }
          }
        }

        .desc-wrapper {
          background: #ffffff;
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .select-desc-wrapper {
            width: 130px;
            height: 35px;
            background-color: #f5f5f5;
            border: 1px solid #d4d4d4;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            .select-desc-item {
              width: 48%;
              height: 90%;
              border-radius: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              p {
                font-size: 12px;
                margin: 0;
                color: #b1b1b1;

                &.selected {
                  color: #ffffff;
                }
              }

              &.selected {
                background-color: #2ac940;
              }
            }
          }
        }

        .catalog-modal-item-qty {
          height: 100%;
          width: 30%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          &.ne-catalog-modal-item-qty {
            width: calc(100% - 120px) !important;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;

            @include smdevice {
              width: calc(100% - 105px) !important;
            }

            @include tablet {
              width: calc(100% - 105px) !important;
            }

            .pdt-price-input-wrapper {
              display: flex;
              align-items: flex-end;
              width: 100%;
              justify-content: space-between;
            }
          }

          .catalog-qty-input {
            height: 35px;
            width: 80px;
            font-size: 14px;
            border: 1px solid #d6d7da;
            border-radius: 3px;
            padding: 5px;
            background-color: #e5eaef;
            color: #989898;

            &.ne-catalog-qty-input {
              font-size: 16px;
            }

            input {
              height: 35px;
              padding: 0;
            }

            &::before {
              border-bottom: none;
            }

            &::after {
              border-bottom: none;
            }

            &.err-qty {
              border-color: red;
            }
          }

          .ne-pdt-details {
            font-size: 16px;
            color: black;
            margin-bottom: 0;

            // &:nth-child(2) {
            //   font-weight: bold !important;
            // }
          }

          .ne-ellipsis {
            @include line-clamp(36px, 18px);
          }

          .ne-ellipsis-pc {
            @include line-clamp(20px, 30px, 1);
          }

          p {
            font-size: 12px;
            color: red;
            margin: 0;
          }

          &.catalog-modal-item-input {
            flex-direction: column;
            align-items: flex-end;
            // justify-content: space-evenly;
            // justify-content: flex-start;
          }

          .disc-wrapper {
            font-size: 12px;
            color: #989898;
            cursor: pointer;
            padding-top: 5px;

            .disc-tax-down-icon {
              padding-left: 5px;
            }
          }
        }
      }

      .catalog-data-spinner {
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .add-more-wrapper {
        &.ne-add-more-wrapper {
          width: 100%;
        }

        p {
          margin-bottom: 0;
          text-align: center;
          color: #3399ff;
          font-size: 16px;
          padding: 5px 0;
          cursor: pointer;
        }
      }
    }

    .buttons-wrapper {
      width: 100%;
      height: 6%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      // padding-bottom: 10px !important;
      .form-create-button {
        @include button(100px, 40px, 25px, 18px, 24px, #00acf1, #ffffff);
      }
    }
  }
}

.summary-view-wrapper {
  width: 100%;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // padding: 0 20px;
  align-items: flex-start;
  padding: 20px 20px 0 20px;
  border-radius: 3px;

  &.ne-summary-view-wrapper {
    padding: 10px 20px 0 20px;
  }

  p {
    font-size: 12px;
    color: #989898;
    margin-bottom: 0;

    &.ne-p {
      font-size: 16px;
    }
  }

  .price-wrapper {
    display: flex;
    flex-direction: row;

    p {
      font-weight: bold;
    }

    i {
      font-size: 18px;
      color: #fa9917;
    }
  }
}
