@import './../../../../scss/mixins';

#vendor-success-root {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  text-align: center;
  .vendor-success-logo-wrapper {
    margin-bottom: 50px;
    .logo {
      width: 250px;
      @include desktop {
        width: 300px;
      }
    }
  }
  .vendor-success-content-wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    @include desktop {
      width: 100%;
    }
    p {
      color: #4a4a4a;
      font-size: 24px;
      margin-bottom: 0;
      margin-bottom: 100px;
    }
    .add-new-button {
      @include button(150px, 45px, 25px, 18px, 24px, #00acf1, #ffffff);
    }
  }
}
