@import './../../../../scss/mixins';
#create-form-root {
  width: 100%;
  height: 100%;
  padding-left: 26px;
  display: flex;
  flex-direction: row;
  .create-form-wrapper {
    width: 100%;
    height: 100%;
    .create-form-header {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      i {
        color: #424c9f;
        font-size: 18px;
      }
      p {
        color: #4a4a4a;
        font-size: 24px;
        margin-bottom: 0;
      }
    }
    .form-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      .form-left {
        width: 50%;
      }
      .buttons-wrapper {
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .form-create-button {
          @include button(150px, 45px, 25px, 18px, 24px, #00acf1, #ffffff);
        }
        .form-disabled-button {
          @include button(
            150px,
            45px,
            25px,
            18px,
            24px,
            rgba(0, 0, 0, 0.12),
            rgba(0, 0, 0, 0.26)
          );
        }
      }
    }
  }
}

.overlay {
  z-index: 2000;
}

.modal {
  display: block !important;
  position: unset !important;
}
