@import './../../../../scss/mixins';
#vendor-create-form-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  @include desktop {
    padding-left: 26px;
  }
  .vendor-create-form-wrapper {
    width: 100%;
    height: 100%;
    .vendor-create-form-header {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      @include desktop {
        padding-left: 25%;
        justify-content: unset;
      }
      i {
        color: #424c9f;
        font-size: 18px;
      }
      p {
        color: #4a4a4a;
        font-size: 24px;
        margin-bottom: 0;
      }
    }
    .vendor-form-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      .vendor-form-left {
        width: 90%;
        @include desktop {
          width: 50%;
        }
      }
      .vendor-buttons-wrapper {
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .form-create-button {
          @include button(150px, 45px, 25px, 18px, 24px, #00acf1, #ffffff);
        }
        .form-disabled-button {
          @include button(
            150px,
            45px,
            25px,
            18px,
            24px,
            rgba(0, 0, 0, 0.12),
            rgba(0, 0, 0, 0.26)
          );
        }
      }
    }
  }
  .vendor-choose-file {
    position: relative;
    height: 50px;
    background-color: #ffffff;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    letter-spacing: 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    i {
      font-size: 20px;
      padding-left: 20px;
    }
    & > span {
      display: flex;
      justify-content: flex-start;
    }
    &.error {
      border: 1px solid #f44336;
      color: #f44336;
    }
  }
  .vendor-file-input {
    display: none;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .vendor-picker-label {
    top: -5px !important;
    left: 15px !important;
  }
  .vendor-logo-wrapper {
    margin-bottom: 5%;
    .logo {
      width: 250px;
      @include desktop {
        width: 300px;
      }
    }
  }
}

.overlay {
  z-index: 2000;
}

.modal {
  display: block !important;
  position: unset !important;
}
