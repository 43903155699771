@import './../../../../scss/mixins';
#guest-summary-root {
  width: 100%;
  height: 100%;
  .guest-summary-wrapper {
    width: 75%;
    margin: 0 auto;
    padding: 25px 0;
    @include smdevice {
      width: 90%;
    }
  }
  .guest-summary-header {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    i {
      color: #424c9f;
      font-size: 18px;
    }
    p {
      color: #4a4a4a;
      font-size: 24px;
      margin-bottom: 0;
    }
  }
  .guest-summary-content {
    width: 100%;
    height: 90%;
    padding-left: 36px;
    @include smdevice {
      padding-left: 0;
    }
    .guest-summary-content-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      @include smdevice {
        flex-direction: column;
      }
      .name-sku-list-wrapper {
        width: 58.5%;
        @include smdevice {
          width: 100%;
        }
        .name-wrapper {
          // border: 0.5px solid #686868;
          // border-radius: 3px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
          padding: 10px;
          h5 {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0;
            width: 100%;
            padding-bottom: 5px;
            &:first-child {
              span {
                font-weight: bold;
              }
            }
          }
          .address-textarea {
            width: 100%;
            color: #4a4a4a;
            font-size: 16px;
            border: none;
            line-height: 24px;
            &:focus-visible {
              outline: none;
            }
          }
        }
        .sku-list-title-wrapper {
          padding-top: 20px;

          .sku-list-wrapper {
            // border: 0.5px solid #686868;
            // border-radius: 3px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
            .title {
              color: #4a4a4a;
              font-size: 20px;
              margin-bottom: 0;
              font-weight: bold;
              padding: 20px 10px;
            }
          }
          .gsummary-catalog-item-wrapper {
            // border-bottom: 0.5px solid #686868;
            padding: 10px 10px 20px;
            &:last-child {
              border-bottom: none;
            }
            .gsummary-code-name-wrapper {
              display: flex;
              p {
                &:first-child {
                  width: 80%;
                }
                &:last-child {
                  width: 20%;
                  text-align: right;
                }
              }
            }
            p {
              font-size: 16px;
              color: #4a4a4a;
              margin-bottom: 0;
            }
            .gsummary-price-wrapper {
              i {
                font-size: 18px;
                color: #fa9917;
              }
            }
            .price {
              font-weight: bold;
            }
          }
        }
        .button-wrapper {
          padding: 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .form-update-button {
            @include button(100%, 45px, 25px, 18px, 24px, #f3ca3e, #4a4a4a);
            font-weight: bold;
          }
          .disabled-button {
            @include button(
              100%,
              45px,
              25px,
              18px,
              24px,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0.26)
            );
          }
        }
      }
      .price-details-root {
        width: 38.5%;
        @include smdevice {
          width: 100%;
        }
        .price-details-wrapper {
          // border: 0.5px solid #686868;
          // border-radius: 3px;
          padding: 10px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
          .details-title {
            color: #989898;
          }
          p {
            color: #4a4a4a;
            margin-bottom: 0;
            font-weight: bold;
          }
          .account-details-wrapper {
            .value-wrapper {
              display: flex;
              justify-content: space-between;
              padding-bottom: 5px;
              &:last-child {
                padding-top: 20px;
                padding-bottom: 0px;
                h5 {
                  font-weight: bold;
                }
              }
            }
            h5 {
              color: #4a4a4a;
              font-size: 16px;
              margin-bottom: 0;
            }
          }
        }
        .button-wrapper {
          padding: 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .form-update-button {
            @include button(100%, 45px, 25px, 18px, 24px, #00acf1, #4a4a4a);
            font-weight: bold;
          }
          .disabled-button {
            @include button(
              100%,
              45px,
              25px,
              18px,
              24px,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0.26)
            );
          }
        }
        .support-wrapper {
          p {
            color: #4a4a4a;
            font-size: 16px;
            margin-bottom: 0;
            a {
              color: #00acf1;
            }
          }
        }
      }
    }
  }
  .guest-success-wrapper {
    width: 100%;
    height: 100%;
    .guest-success-content-wrapper {
      width: 60%;
      height: 100%;
      margin: 0 auto;
      padding: 50px 0;
      text-align: center;
      @include smdevice {
        width: 90%;
      }
      .circle-check-icon {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-color: #2ac940;
        margin: 0 auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 25px;
          color: #fff;
        }
      }
      p {
        color: #4a4a4a;
      }
      .order-successfull {
        font-size: 20px;
        font-weight: bold;
      }
      .order-no {
        border: 0.5px solid rgba(0, 0, 0, 0.15);
        padding: 10px;
        color: #4a4a4a;
      }
      .order-email {
        margin-top: 20px;
      }
    }
  }
  .irctc-user-logo-wrapper {
    margin-bottom: 30px;
    .logo {
      width: 250px;
      @include desktop {
        width: 280px;
      }
    }
  }
}
