@import './../../../scss/mixins';
%searchText {
  border: none;
  background: none;
  outline: none;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 12px;
  width: 170px;
}
%searchBox {
  height: 40px;
  border-radius: 10px;
  padding-left: 10px;
  width: 215px;
  display: flex;
}
.filter-data-container {
  width: 100%;
  background-color: #f1f4f7;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 10px 20px 0 rgba(42, 74, 100, 0.16);
  &.ne-filter-data-container{
    width: 95%;
    margin: 0 auto 10px;
  }
  .filter-data-title {
    p {
      color: #177871;
      font-size: 20px;
      padding: 5px;
      margin-bottom: 0;
    }
  }
  .filter-data-tab-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .filter-data-select {
      width: 16%;
      margin: 3px 30px 5px 3px;
      &.ne-filter-data-select{
        width: 25%;
        @include smdevice {
          width: 100%;
          margin-right: 0;
        }
      }
      p {
        margin-bottom: 0px;
        font-size: 12px;
      }
      button {
        &:focus {
          outline: none;
        }
      }
    }
  }
  .filter-data-buttons-wrapper {
    padding-top: 10px;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    .filter-data-apply-button {
      @include button(120px, 40px, 45px, 16px, 24px, #4a90e2, #ffffff);
      &.ne-filter-data-apply-button {
        @include button(100px, 40px, 25px, 16px, 24px, #00acf1, #ffffff);
      }
    }

    .filter-data-clear-button {
      @include button(120px, 40px, 45px, 16px, 24px, #ffffff, #9b9b9b);
      margin-right: 20px;
      &.ne-filter-data-clear-button{
        @include button(100px, 40px, 45px, 16px, 24px, #ffffff, #9b9b9b);
      }
    }

    .filter-data-disabled-button {
      @include button(
        120px,
        40px,
        45px,
        16px,
        24px,
        rgba(0, 0, 0, 0.12),
        rgba(0, 0, 0, 0.26)
      );
    }
  }
}

.workflow-data-search-wrapper {
  @extend %searchBox;
  background: linear-gradient(0deg, #f8f8f8 0%, #ececec 100%);
  .workflow-data-search-btn {
    color: #4a4a4a;
    padding: 10px 10px;
    cursor: pointer;
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
  }
  .workflow-data-search-text {
    @extend %searchText;
  }
}

.custom-date-dialog {
  .title {
    text-align: center;
  }
  .custom-date-wrapper {
    display: flex;
    flex-direction: row;
    .custom-date-select {
      padding: 10px;
      .custom-date-select-lable {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
        color: #2a2a2a;
      }
      .date-select {
        border-bottom: 1px solid #cfd3d4;
        height: 40px;
        width: 100%;
        padding: 9px;
        div {
          &:before {
            border-bottom: none;
          }
          &:after {
            border-bottom: none;
          }
        }
      }
    }
  }
  .custom-date-select-cancel {
    @include button(120px, 40px, 45px, 16px, 24px, #ffffff, #9b9b9b);
    margin-right: 20px;
  }
  .custom-date-select-done {
    @include button(120px, 40px, 45px, 16px, 24px, #4a90e2, #ffffff);
  }
}

.picky__input {
  height: 30px;
  border: 1px solid #cfd3d4;
  border-radius: 3px;
  padding: 1px 7px 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    top: 13px;
  }
}
.picky__dropdown {
  border: 1px solid #cfd3d4;
}
.picky__input,
.picky__dropdown,
.option {
  font-size: 14px;
}
.picky__dropdown .option:focus,
.picky__dropdown li:focus {
  outline: none !important;
}
