#home-root {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 26px;
  overflow-y: auto;
}
#home-root .apps-wrapper {
  width: 70%;
}
#home-root .apps-wrapper .app-cards-root {
  height: 90%;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper {
  width: 175px;
  height: 125px;
  margin: 5px 35px 40px 5px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  cursor: pointer;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper h6 {
  width: 120px;
  height: 61px;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
  color: #3a507e;
  padding-top: 20px;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  line-height: 20px;
  height: 61px;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper .rectangle-5 {
  cursor: pointer;
  height: 40px;
  width: 175px;
  border-radius: 0 0 3px 3px;
  background-color: #00acf1;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper .rectangle-5::before {
  content: "";
  width: 100%;
  height: 24px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: -30px;
  transform: skew(-40deg) scale(2.1) rotateZ(-4deg);
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper .rectangle-5 p {
  margin: 0;
  font-size: 10px;
  color: #ffffff;
  padding-left: 15px;
  padding-top: 10px;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper .rectangle-5 i {
  font-size: 15px;
  color: #ffffff;
  padding-right: 10px;
  padding-top: 10px;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper .selected-wrapper {
  background: #00acf1;
  height: 10px;
  width: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  bottom: -10px;
  right: 5px;
}
#home-root .apps-wrapper .app-cards-root .app-cards-wrapper .app-card-wrapper .selected-wrapper .selected-workflow-icon {
  color: #ffffff;
  position: relative;
  top: -7px;
  right: -5px;
}
#home-root .header {
  height: 10%;
  display: flex;
  align-items: center;
}
#home-root .header.task-header {
  width: 95%;
  margin: 0 auto;
}
#home-root .header h5 {
  font-size: 25px;
  color: #4a4a4a;
}
#home-root #updates-wrapper {
  width: 30%;
  background-color: #e5eaef;
}
#home-root #updates-wrapper .no-pending-tasks {
  width: 95%;
  margin: 0 auto;
}
#home-root #updates-wrapper .task-content-wrapper {
  background-color: #e5eaef;
  padding-bottom: 1px;
}
#home-root #updates-wrapper .tabel-footer {
  background-color: #e5eaef;
}
#home-root #updates-wrapper .update-card-wrapper {
  width: 95%;
  margin: 0 auto 15px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
#home-root #updates-wrapper .update-card-wrapper .task-title {
  padding-top: 5px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
#home-root #updates-wrapper .date-time-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
#home-root #updates-wrapper .date-time-wrapper .date-time-card {
  height: 35px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
#home-root #updates-wrapper .date-time-wrapper .date-time-icon {
  color: #00acf1;
  padding-right: 5px;
}
#home-root #updates-wrapper .date-time-wrapper .date-card {
  width: 100px;
  margin-right: 15px;
}
#home-root #updates-wrapper .date-time-wrapper .time-card {
  width: 90px;
}

