#app-function-wrapper {
  height: 190px;
  width: 595px;
  border-radius: 3px;
  padding-left: 20px;
  background-color: #e5eaef;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
#app-function-wrapper .app-function-title {
  height: 65px;
  display: flex;
  align-items: center;
}
#app-function-wrapper .app-function-title h6 {
  color: #4a4a4a;
  font-size: 20px;
}
#app-function-wrapper .app-function-card-wrapper {
  display: flex;
  flex-direction: row;
}
#app-function-wrapper .app-function-card-wrapper .app-function-card {
  height: 105px;
  width: 75px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
#app-function-wrapper .app-function-card-wrapper .app-function-card i {
  font-size: 28px;
  font-weight: 300;
}
#app-function-wrapper .app-function-card-wrapper .app-function-card p {
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  line-height: 14px;
  height: 26px;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#app-function-wrapper .app-function-card-wrapper .app-function-card.disabled {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

