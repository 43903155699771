.logincard {
  // height: 590px;
  height: 100%;
  width: 370px;
  background-color: #ffffff;
  box-shadow: 0 10px 35px 0 rgba(60, 66, 69, 0.2);
  position: relative;
  right: 7%;
  padding: 30px 25px;
  z-index: 3;
  display: flex;
  align-items: center;
  .powered-by {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    p {
      margin-bottom: 0;
      font-size: 10px;
      line-height: 12px;
      color: #00acf1;
      padding-right: 10px;
    }
    img {
      width: 130px;
    }
  }
  .content {
    width: 100%;
    ::placeholder {
      color: #00acf1;
      font-size: 15px;
      line-height: 17px;
    }
    h2 {
      color: #00acf1;
      font-size: 30px;
      line-height: 36px;
    }

    h6 {
      color: #00acf1;
      float: right;
      cursor: pointer;
    }

    p {
      font-size: 16px;
      letter-spacing: -0.24px;
      line-height: 24px;
    }

    .input-title {
      color: #9b9b9b;
      font-size: 14px;
      font-style: italic;
      line-height: 16px;
      padding-left: 12px;
    }

    .login-input-title {
      color: #9b9b9b;
      font-size: 14px;
      line-height: 16px;
      padding: 10px 0;
    }

    .login-input-title > span {
      padding: 2px;
      cursor: pointer;
    }

    .login-input-title > span:nth-child(1),
    .login-input-title > span:nth-child(3) {
      text-decoration: underline;
    }

    .active {
      color: #00acf1;
    }

    input {
      box-shadow: none;
    }

    .input-field {
      position: relative;
      z-index: 4;
      border: none;
      // padding-bottom: 20px;
    }

    .under-line {
      height: 2px;
      width: 100%;
      border-radius: 2px;
      background: linear-gradient(270deg, #03f597 0%, #0081ff 100%);
    }

    .submit-button {
      width: 100%;
      height: 49px;
      // margin-top: 20px;
      margin-bottom: 5px;
      text-align: end;
      background: linear-gradient(270deg, #008fc4 0%, #026489 100%);
      font-size: 15px;
    }
    #g-recaptcha {
      padding-top: 10px;
    }
    .react-tel-input {
      input {
        width: 100%;
      }
    }
  }

  .otp-placeholder {
    ::placeholder {
      color: #9b9b9b;
      font-size: 15px;
      line-height: 17px;
    }
  }

  .paddingLeft-20 {
    padding-left: 20px;
  }
  .color-red {
    color: #d0021b;
  }
  .error-msg {
    margin-bottom: 0;
    padding-top: 10px;
  }
  .marginTop-20 {
    margin-top: 20px;
  }
}
