#otp-modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#otp-modal-wrapper .otp-modal-container {
  width: 50%;
  height: 30%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  #otp-modal-wrapper .otp-modal-container {
    width: 90%;
    min-height: 300px;
  }
}
#otp-modal-wrapper .otp-modal-container .otp-modal-header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid black;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-header p {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 0;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-header .close-button {
  color: #00acf1;
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
  font-weight: 300;
  position: absolute;
  right: 2%;
  cursor: pointer;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content {
  width: 60%;
}
@media (max-width: 767px) {
  #otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content {
    width: 80%;
  }
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content input {
  box-shadow: none;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .login-input-title {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 0;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .login-input-title .active {
  color: #00acf1;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .login-input-title > span {
  padding: 2px;
  cursor: pointer;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .login-input-title > span:nth-child(1),
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .login-input-title > span:nth-child(3) {
  text-decoration: underline;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .input-field {
  position: relative;
  z-index: 4;
  border: none;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .under-line {
  height: 2px;
  width: 100%;
  border-radius: 2px;
  background: linear-gradient(270deg, #03f597 0%, #0081ff 100%);
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .submit-button {
  width: 100%;
  height: 49px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: end;
  background: linear-gradient(270deg, #008fc4 0%, #026489 100%);
  font-size: 15px;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .paddingLeft-20 {
  padding-left: 20px;
}
#otp-modal-wrapper .otp-modal-container .otp-modal-body .otp-modal-content .react-tel-input .form-control {
  width: 100%;
}

