#login {
  width: 100%;
  height: 100vh;
  position: relative;
  background: linear-gradient(220deg, rgb(0, 255, 180), rgba(0, 255, 180, 0) 50.71%), linear-gradient(160deg, rgb(16, 139, 240), rgba(16, 139, 240, 0) 70.71%);
}
#login:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url(../../../assets/images/userOnBoarding/bg-left.png) left bottom no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
#login:after {
  content: "";
  width: 100%;
  height: 250px;
  background: url(../../../assets/images/userOnBoarding/fade-before.png) bottom left repeat-x;
  position: absolute;
  left: 0;
  bottom: 0;
}
#login .image-topright {
  position: absolute;
  right: 3%;
  width: 471px;
  height: 439px;
  background: url(../../../assets/images/userOnBoarding/circle.png) right top no-repeat;
  top: 50%;
  transform: translate(0, -65%);
}
#login .content-login {
  width: 65%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 10%;
}
#login .content-login .owl-item-holder .img-holder {
  width: 160px;
  height: 157px;
}
#login .content-login .owl-item-holder h1 {
  color: #0380b3;
  letter-spacing: -0.88px;
  line-height: 49px;
  padding-top: 25px;
}
#login .content-login .owl-item-holder h1 i {
  font-size: 60px;
  font-weight: 700;
}
#login .content-login .owl-item-holder h1 span {
  font-size: 40px;
}
#login .content-login .owl-theme .owl-dots {
  position: absolute;
  top: 100%;
  left: 0;
}
#login .content-login .owl-theme .owl-dots .owl-dot.active span {
  width: 50px;
  background: #00acf1;
}
#login .content-login .owl-theme .owl-dots .owl-dot span {
  background: #00ffc6;
  border-radius: 0;
  width: 25px;
  height: 4px;
}
#login .card1 {
  height: 100%;
}

