.basic-container,
.form-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .upgrade-company-logo {
    margin-bottom: 5%;
    .logo {
      width: 300px;
    }
  }
  h1 {
    font-size: 36px;
    color: #35c1b8;
  }
  h6 {
    font-size: 24px;
    color: #4a4a4a;
  }
  .form-container {
    width: 30%;
    max-width: 40%;
    h1 {
      text-align: center;
      font-size: 36px;
      color: #35c1b8;
    }
  }
  .button-wrapper {
    margin-top: 1%;
    .upgrade-button {
      height: 40px;
      width: 190px;
      border-radius: 3px;
      font-size: 18px;
      line-height: 15px;
      background-color: #4a90e2;
      color: #ffffff;
      &:hover {
        background-color: #4a90e2;
        color: #ffffff;
      }
    }
  }
  .upgradeform-submit {
    height: 40px;
    width: 190px;
    float: right;
  }
  .upgradeform-submit-active {
    background-color: #4a90e2;
    color: #ffffff;
    &:hover {
      background-color: #4a90e2;
      color: #ffffff;
    }
  }
}
