@import './../../../../scss/mixins';

#guest-user-create-form-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @include desktop {
    padding-left: 26px;
  }

  .guest-user-create-form-wrapper {
    width: 100%;
    height: 100%;

    .guest-user-create-form-header {
      width: 100%;
      margin-bottom: 20px;

      p {
        width: 90%;
        color: #4a4a4a;
        font-size: 28px;
        margin: 0 auto;
        text-align: center;
        font-weight: bold;
      }
    }

    .guest-user-form-content {
      width: 100%;
      display: flex;
      flex-direction: row;

      .guest-user-form-left {
        width: 90%;

        @include desktop {
          width: 50%;
        }

        .no-access-token {
          color: #4a4a4a;
          font-size: 24px;
          margin-bottom: 100px;
          text-align: center;
        }

        .powered-by-wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          margin: 0 10px 10px;

          p {
            margin: 0;
            padding-right: 20px;
          }

          img {
            width: 100px;
          }

          &.ne-powered-by-wrapper {
            justify-content: unset;
            @include smdevice{
              display: block;
            }
          }

          .ne-policy-wrapper {
            width: 50%;
            display: flex;
            flex-direction: column;
            @include smdevice{
              width: 100%;
            }
            a {
              font-size: 16px;
              color: #212529;
              @include smdevice{
                font-size: 12px;
              }
            }
          }

          .powered-by-logo-wrapper {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            @include smdevice{
              width: 100%;
            }
          }
        }
      }

      .guest-user-buttons-wrapper {
        width: 100%;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .form-create-button {
          @include button(150px, 45px, 25px, 18px, 24px, #00acf1, #ffffff);
        }

        .form-disabled-button {
          @include button(150px,
            45px,
            25px,
            18px,
            24px,
            rgba(0, 0, 0, 0.12),
            rgba(0, 0, 0, 0.26));
        }
      }
    }
  }

  .guest-user-logo-wrapper {
    margin-bottom: 10px;

    .logo {
      width: 250px;

      @include desktop {
        width: 300px;
      }
    }
  }

  .naveen-user-logo-wrapper {
    margin-bottom: 10px;

    .logo {
      width: 240px;

      @include desktop {
        width: 320px;
      }
    }
  }

  .captch-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
}

#guest-user-success-root {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  text-align: center;

  .guest-user-success-logo-wrapper {
    margin-bottom: 50px;

    .logo {
      width: 250px;

      @include desktop {
        width: 300px;
      }
    }
  }

  .guest-user-success-content-wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto;

    @include desktop {
      width: 100%;
    }

    p {
      color: #4a4a4a;
      font-size: 24px;
      margin-bottom: 0;
      margin-bottom: 100px;
    }
  }
}

.overlay {
  z-index: 2000;
}

.modal {
  display: block !important;
  position: unset !important;
}