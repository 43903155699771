.team-user-details-wrapper .team-user-details .drawer-open-p, .team-user-details-wrapper .team-user-details .drawer-close-p, .team-user-details-wrapper .team-user-details .drawer-open-h6, .team-user-details-wrapper .team-user-details .drawer-close-h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-user-details-wrapper {
  height: 95px;
  display: flex;
  align-items: center;
  background-color: #6471de;
}
.team-user-details-wrapper.padding-left-20 {
  padding-left: 20px;
}
.team-user-details-wrapper .team-image-root {
  width: 80px;
  display: flex;
}
.team-user-details-wrapper .team-image-root.width-100 {
  width: 100px;
  justify-content: center;
}
.team-user-details-wrapper .team-image-root .team-image-wrapper {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  font-size: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(90, 91, 99, 0.4);
}
.team-user-details-wrapper .team-user-details {
  width: 160px;
}
.team-user-details-wrapper .team-user-details h6 {
  color: #ffffff;
  font-size: 25px;
  line-height: 25px;
  margin: 0;
  padding-bottom: 5px;
}
.team-user-details-wrapper .team-user-details .drawer-close-h6 {
  width: 70px;
}
.team-user-details-wrapper .team-user-details .drawer-open-h6 {
  width: 150px;
}
.team-user-details-wrapper .team-user-details p {
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}
.team-user-details-wrapper .team-user-details .drawer-open-p {
  width: 150px;
}
.team-user-details-wrapper i {
  color: #ffffff;
  font-size: 15px;
}

.toggle-icon {
  width: 18px;
  cursor: pointer;
  height: 40px;
  position: relative;
  top: 25px;
  border-radius: 0 10px 10px 0;
  background-color: #ffffff;
  color: #6471de;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
}
.toggle-icon span {
  width: 18px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-modal-wrapper {
  width: 215px;
  border: 1px solid #35c1b8;
  box-shadow: 0 10px 20px 0;
  border-radius: 0 0 3px 3px;
  background-color: #ffffff;
}
.team-modal-wrapper .team-wrapper .selected-team {
  border-bottom: 1px solid rgb(195, 236, 233);
  background-color: rgb(255, 255, 255);
  padding: 5px;
}
.team-modal-wrapper .team-wrapper .selected-team.active {
  background-color: #6471de;
  color: #ffffff;
}

