@import './../../../scss/mixins';

.menu-price-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .react-pdf__Page__canvas{
    @include smdevice {
      width: 100%  !important;
      height: 100%  !important;
    }
  }
  .react-pdf__Page__textContent{
    @include smdevice {
      width: 100%  !important;
    }
  }
}