@import './../../../scss/mixins';
#app-function-wrapper {
  height: 190px;
  width: 595px;
  border-radius: 3px;
  padding-left: 20px;
  background-color: #e5eaef;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  .app-function-title {
    height: 65px;
    display: flex;
    align-items: center;
    h6 {
      color: #4a4a4a;
      font-size: 20px;
    }
  }
  .app-function-card-wrapper {
    display: flex;
    flex-direction: row;
    .app-function-card {
      height: 105px;
      width: 75px;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
      i {
        font-size: 28px;
        font-weight: 300;
      }
      p {
        font-size: 14px;
        color: #9b9b9b;
        margin-bottom: 0;
        @include line-clamp(26px, 14px);
      }
      &.disabled {
        cursor: default;
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}
