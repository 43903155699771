#vendor-create-form-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
@media (min-width: 1024px) {
  #vendor-create-form-root {
    padding-left: 26px;
  }
}
#vendor-create-form-root .vendor-create-form-wrapper {
  width: 100%;
  height: 100%;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-create-form-header {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  #vendor-create-form-root .vendor-create-form-wrapper .vendor-create-form-header {
    padding-left: 25%;
    justify-content: unset;
  }
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-create-form-header i {
  color: #424c9f;
  font-size: 18px;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-create-form-header p {
  color: #4a4a4a;
  font-size: 24px;
  margin-bottom: 0;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-form-left {
  width: 90%;
}
@media (min-width: 1024px) {
  #vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-form-left {
    width: 50%;
  }
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-buttons-wrapper {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-buttons-wrapper .form-create-button {
  height: 45px;
  width: 150px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: #00acf1;
  color: #ffffff;
  text-transform: capitalize;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-buttons-wrapper .form-create-button:focus {
  outline: none;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-buttons-wrapper .form-create-button:hover {
  background-color: #00acf1;
  color: #ffffff;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-buttons-wrapper .form-disabled-button {
  height: 45px;
  width: 150px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  text-transform: capitalize;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-buttons-wrapper .form-disabled-button:focus {
  outline: none;
}
#vendor-create-form-root .vendor-create-form-wrapper .vendor-form-content .vendor-buttons-wrapper .form-disabled-button:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
#vendor-create-form-root .vendor-choose-file {
  position: relative;
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  letter-spacing: 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
#vendor-create-form-root .vendor-choose-file i {
  font-size: 20px;
  padding-left: 20px;
}
#vendor-create-form-root .vendor-choose-file > span {
  display: flex;
  justify-content: flex-start;
}
#vendor-create-form-root .vendor-choose-file.error {
  border: 1px solid #f44336;
  color: #f44336;
}
#vendor-create-form-root .vendor-file-input {
  display: none;
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
#vendor-create-form-root .vendor-picker-label {
  top: -5px !important;
  left: 15px !important;
}
#vendor-create-form-root .vendor-logo-wrapper {
  margin-bottom: 5%;
}
#vendor-create-form-root .vendor-logo-wrapper .logo {
  width: 250px;
}
@media (min-width: 1024px) {
  #vendor-create-form-root .vendor-logo-wrapper .logo {
    width: 300px;
  }
}

.overlay {
  z-index: 2000;
}

.modal {
  display: block !important;
  position: unset !important;
}

