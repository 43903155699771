@import './../../../../scss/mixins';

.lookup-modal-root {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.select-root {
    height: 90vh;
  }
  #lookup-modal-wrapper {
    width: 95%;
    // min-height: 90%;
    // max-height: 95%;
    height: 95%;
    // overflow-y: auto;
    background-color: #e5eaef;
    border-radius: 3px;

    .lookup-modal-title-wrapper {
      // height: 40px;
      height: 6%;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.select-title-wrapper {
        height: 10%;
        margin-top: 0;
      }
      &.lookup-new-wrapper {
        flex-direction: column;
        margin-bottom: 20px;
        align-items: unset;
        &.user-directory-title-wrapper {
          height: 10%;
        }
        .lookup-new-close-icon {
          text-align: right;
          padding-bottom: 20px;
          margin-right: 30px;
          &.user-directory-title-close-icon {
            padding-bottom: 0;
          }
          i {
            font-size: 20px;
            color: #424c9f;
          }
        }
        .lookup-new-title-search-wrapper {
          display: flex;
          justify-content: space-between;
          margin-right: 30px;
        }
      }
      .title {
        // width: 170px;
        height: 40px;
        border-radius: 0 20px 20px 0;
        background-color: #6471de;
        padding: 0 10px 0 30px;

        p {
          // width: 160px;
          height: 40px;
          line-height: 40px;
          max-width: 300px;
          font-size: 24px;
          color: #ffffff;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .search-filter-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .cancel-icon {
          font-size: 18px;
          padding: 0 30px 0 20px;
          color: #424c9f;
        }
      }
    }

    .lookup-modal-content-wrapper {
      padding: 0 30px 10px 30px;
      height: 74%;
      overflow-y: auto;

      .lookup-options-value {
        height: 45px;
        font-size: 16px;
        padding-left: 5px;
        margin-bottom: 10px;
        border: 1px solid #979797;
        background-color: #ffffff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: hidden;
      }
    }

    .tabel-footer {
      button {
        &:focus {
          outline: none;
        }
      }
    }
  }
  .lookup-data-spinner {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.checklist-modal-title-wrapper {
  // height: 40px;
  height: 6%;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.select-footer-wrapper {
    height: 7%;
    align-items: flex-end;
    margin-top: 0;
  }
  .title {
    width: 100%;
    height: 40px;
    // border-radius: 0 20px 20px 0;
    background-color: #00acf1;
    padding: 0 10px 0 30px;

    p {
      // width: 160px;
      height: 40px;
      line-height: 40px;
      width: 100%;
      font-size: 24px;
      color: #ffffff;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}

.add-button-wrapper {
  .add-button {
    @include button(100%, 40px, 0, 24px, 0, #00acf1, #ffffff);
  }
  .add-button-disabled {
    @include button(
      100%,
      40px,
      0,
      24px,
      0,
      rgba(0, 0, 0, 0.12),
      rgba(0, 0, 0, 0.26)
    );
  }
}

.no-records-found {
  width: 100%;
  color: #4a4a4a;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
}
