#guest-user-create-form-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
@media (min-width: 1024px) {
  #guest-user-create-form-root {
    padding-left: 26px;
  }
}
#guest-user-create-form-root .guest-user-create-form-wrapper {
  width: 100%;
  height: 100%;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-create-form-header {
  width: 100%;
  margin-bottom: 20px;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-create-form-header p {
  width: 90%;
  color: #4a4a4a;
  font-size: 28px;
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left {
  width: 90%;
}
@media (min-width: 1024px) {
  #guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left {
    width: 50%;
  }
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .no-access-token {
  color: #4a4a4a;
  font-size: 24px;
  margin-bottom: 100px;
  text-align: center;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 10px 10px;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper p {
  margin: 0;
  padding-right: 20px;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper img {
  width: 100px;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper.ne-powered-by-wrapper {
  justify-content: unset;
}
@media (max-width: 767px) {
  #guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper.ne-powered-by-wrapper {
    display: block;
  }
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper .ne-policy-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  #guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper .ne-policy-wrapper {
    width: 100%;
  }
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper .ne-policy-wrapper a {
  font-size: 16px;
  color: #212529;
}
@media (max-width: 767px) {
  #guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper .ne-policy-wrapper a {
    font-size: 12px;
  }
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper .powered-by-logo-wrapper {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  #guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-form-left .powered-by-wrapper .powered-by-logo-wrapper {
    width: 100%;
  }
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-buttons-wrapper {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-buttons-wrapper .form-create-button {
  height: 45px;
  width: 150px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: #00acf1;
  color: #ffffff;
  text-transform: capitalize;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-buttons-wrapper .form-create-button:focus {
  outline: none;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-buttons-wrapper .form-create-button:hover {
  background-color: #00acf1;
  color: #ffffff;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-buttons-wrapper .form-disabled-button {
  height: 45px;
  width: 150px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  text-transform: capitalize;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-buttons-wrapper .form-disabled-button:focus {
  outline: none;
}
#guest-user-create-form-root .guest-user-create-form-wrapper .guest-user-form-content .guest-user-buttons-wrapper .form-disabled-button:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
#guest-user-create-form-root .guest-user-logo-wrapper {
  margin-bottom: 10px;
}
#guest-user-create-form-root .guest-user-logo-wrapper .logo {
  width: 250px;
}
@media (min-width: 1024px) {
  #guest-user-create-form-root .guest-user-logo-wrapper .logo {
    width: 300px;
  }
}
#guest-user-create-form-root .naveen-user-logo-wrapper {
  margin-bottom: 10px;
}
#guest-user-create-form-root .naveen-user-logo-wrapper .logo {
  width: 240px;
}
@media (min-width: 1024px) {
  #guest-user-create-form-root .naveen-user-logo-wrapper .logo {
    width: 320px;
  }
}
#guest-user-create-form-root .captch-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

#guest-user-success-root {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  text-align: center;
}
#guest-user-success-root .guest-user-success-logo-wrapper {
  margin-bottom: 50px;
}
#guest-user-success-root .guest-user-success-logo-wrapper .logo {
  width: 250px;
}
@media (min-width: 1024px) {
  #guest-user-success-root .guest-user-success-logo-wrapper .logo {
    width: 300px;
  }
}
#guest-user-success-root .guest-user-success-content-wrapper {
  width: 90%;
  height: 100%;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  #guest-user-success-root .guest-user-success-content-wrapper {
    width: 100%;
  }
}
#guest-user-success-root .guest-user-success-content-wrapper p {
  color: #4a4a4a;
  font-size: 24px;
  margin-bottom: 0;
  margin-bottom: 100px;
}

.overlay {
  z-index: 2000;
}

.modal {
  display: block !important;
  position: unset !important;
}

