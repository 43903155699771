.error-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.error-main .content-404 {
  color: red;
  font-size: 200px;
  line-height: initial;
}
.error-main .content-page {
  font-size: 100px;
  color: #35c1b8;
}

.error-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-wrapper p {
  font-size: 70px;
  color: red;
}

