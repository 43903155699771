#customlogin {
  transition: background-image 2s ease-in-out, background-size 2s ease-in-out;
  width: 100%;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center center;
  animation: changeBackground 15s infinite;
}
#customlogin:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
#customlogin:after {
  content: "";
  width: 100%;
  height: 250px;
  position: absolute;
  left: 0;
  bottom: 0;
}
#customlogin .image-topright {
  position: absolute;
  right: 3%;
  width: 471px;
  height: 439px;
  top: 50%;
  transform: translate(0, -65%);
}
#customlogin .content-login {
  width: 65%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 10%;
}
#customlogin .content-login .owl-item-holder .img-holder {
  width: 160px;
  height: 157px;
}
#customlogin .content-login .owl-item-holder h1 {
  color: #0380b3;
  letter-spacing: -0.88px;
  line-height: 49px;
  padding-top: 25px;
}
#customlogin .content-login .owl-item-holder h1 i {
  font-size: 60px;
  font-weight: 700;
}
#customlogin .content-login .owl-item-holder h1 span {
  font-size: 40px;
}
#customlogin .content-login .owl-theme .owl-dots {
  position: absolute;
  top: 100%;
  left: 0;
}
#customlogin .content-login .owl-theme .owl-dots .owl-dot.active span {
  width: 50px;
  background: #00acf1;
}
#customlogin .content-login .owl-theme .owl-dots .owl-dot span {
  background: #00ffc6;
  border-radius: 0;
  width: 25px;
  height: 4px;
}
#customlogin .card1 {
  height: 100%;
}
#customlogin .spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(90deg, #5ebd3e, #ffb900, #f78200, #e23838); /* Use existing gradient colors */
}
#customlogin .spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ip__worm1,
.ip__worm2 {
  animation: worm1 4s linear infinite;
  stroke-dasharray: 180 656;
}

.ip__worm2 {
  animation-name: worm2;
}

@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: -358;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -358;
  }
}

