#team-list-root .team-list-wrapper .team-wrapper .team-user-details .drawer-open-h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#team-list-root {
  width: 284px;
  background-color: #6471de;
  padding: 0 15px 20px 8px;
  overflow-y: auto;
  scrollbar-color: #ffffff #6471de;
  scrollbar-width: thin;
}
#team-list-root .close-button-wrapper {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#team-list-root .close-button-wrapper i {
  font-size: 20px;
  color: white;
  font-weight: 300;
}
#team-list-root .team-list-wrapper {
  width: 100%;
  min-height: calc(100% - 70px);
}
#team-list-root .team-list-wrapper .team-wrapper {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#team-list-root .team-list-wrapper .team-wrapper .team-image-root {
  width: 70px;
}
#team-list-root .team-list-wrapper .team-wrapper .team-image-root .team-image-wrapper {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  font-size: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(90, 91, 99, 0.4);
}
#team-list-root .team-list-wrapper .team-wrapper .team-user-details {
  width: 170px;
}
#team-list-root .team-list-wrapper .team-wrapper .team-user-details h6 {
  color: #ffffff;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
}
#team-list-root .team-list-wrapper .team-wrapper .team-user-details .drawer-open-h6 {
  width: 160px;
}
#team-list-root .logo-icon-wrapper {
  width: 100%;
  display: flex;
  padding-left: 20px;
}
#team-list-root .logo-icon-wrapper img {
  height: 30px;
  padding-left: 20px;
}
#team-list-root .logo-icon-wrapper i {
  font-size: 30px;
  color: #ffffff;
}

.min-height-100 {
  min-height: 100%;
}

#team-list-root::-webkit-scrollbar {
  width: 6px;
}

#team-list-root::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

#team-list-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #6471de;
}

