.workflow-data-search-wrapper .workflow-data-search-text {
  border: none;
  background: none;
  outline: none;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 12px;
  width: 170px;
}

.workflow-data-search-wrapper {
  height: 40px;
  border-radius: 10px;
  padding-left: 10px;
  width: 215px;
  display: flex;
}

.filter-data-container {
  width: 100%;
  background-color: #f1f4f7;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 10px 20px 0 rgba(42, 74, 100, 0.16);
}
.filter-data-container.ne-filter-data-container {
  width: 95%;
  margin: 0 auto 10px;
}
.filter-data-container .filter-data-title p {
  color: #177871;
  font-size: 20px;
  padding: 5px;
  margin-bottom: 0;
}
.filter-data-container .filter-data-tab-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.filter-data-container .filter-data-tab-wrapper .filter-data-select {
  width: 16%;
  margin: 3px 30px 5px 3px;
}
.filter-data-container .filter-data-tab-wrapper .filter-data-select.ne-filter-data-select {
  width: 25%;
}
@media (max-width: 767px) {
  .filter-data-container .filter-data-tab-wrapper .filter-data-select.ne-filter-data-select {
    width: 100%;
    margin-right: 0;
  }
}
.filter-data-container .filter-data-tab-wrapper .filter-data-select p {
  margin-bottom: 0px;
  font-size: 12px;
}
.filter-data-container .filter-data-tab-wrapper .filter-data-select button:focus {
  outline: none;
}
.filter-data-container .filter-data-buttons-wrapper {
  padding-top: 10px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-apply-button {
  height: 40px;
  width: 120px;
  border-radius: 45px;
  font-size: 16px;
  line-height: 24px;
  background-color: #4a90e2;
  color: #ffffff;
  text-transform: capitalize;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-apply-button:focus {
  outline: none;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-apply-button:hover {
  background-color: #4a90e2;
  color: #ffffff;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-apply-button.ne-filter-data-apply-button {
  height: 40px;
  width: 100px;
  border-radius: 25px;
  font-size: 16px;
  line-height: 24px;
  background-color: #00acf1;
  color: #ffffff;
  text-transform: capitalize;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-apply-button.ne-filter-data-apply-button:focus {
  outline: none;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-apply-button.ne-filter-data-apply-button:hover {
  background-color: #00acf1;
  color: #ffffff;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-clear-button {
  height: 40px;
  width: 120px;
  border-radius: 45px;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  color: #9b9b9b;
  text-transform: capitalize;
  margin-right: 20px;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-clear-button:focus {
  outline: none;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-clear-button:hover {
  background-color: #ffffff;
  color: #9b9b9b;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-clear-button.ne-filter-data-clear-button {
  height: 40px;
  width: 100px;
  border-radius: 45px;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  color: #9b9b9b;
  text-transform: capitalize;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-clear-button.ne-filter-data-clear-button:focus {
  outline: none;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-clear-button.ne-filter-data-clear-button:hover {
  background-color: #ffffff;
  color: #9b9b9b;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-disabled-button {
  height: 40px;
  width: 120px;
  border-radius: 45px;
  font-size: 16px;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  text-transform: capitalize;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-disabled-button:focus {
  outline: none;
}
.filter-data-container .filter-data-buttons-wrapper .filter-data-disabled-button:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.workflow-data-search-wrapper {
  background: linear-gradient(0deg, #f8f8f8 0%, #ececec 100%);
}
.workflow-data-search-wrapper .workflow-data-search-btn {
  color: #4a4a4a;
  padding: 10px 10px;
  cursor: pointer;
}
.workflow-data-search-wrapper .workflow-data-search-btn.disabled {
  cursor: default;
  pointer-events: none;
}
.custom-date-dialog .title {
  text-align: center;
}
.custom-date-dialog .custom-date-wrapper {
  display: flex;
  flex-direction: row;
}
.custom-date-dialog .custom-date-wrapper .custom-date-select {
  padding: 10px;
}
.custom-date-dialog .custom-date-wrapper .custom-date-select .custom-date-select-lable {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  color: #2a2a2a;
}
.custom-date-dialog .custom-date-wrapper .custom-date-select .date-select {
  border-bottom: 1px solid #cfd3d4;
  height: 40px;
  width: 100%;
  padding: 9px;
}
.custom-date-dialog .custom-date-wrapper .custom-date-select .date-select div:before {
  border-bottom: none;
}
.custom-date-dialog .custom-date-wrapper .custom-date-select .date-select div:after {
  border-bottom: none;
}
.custom-date-dialog .custom-date-select-cancel {
  height: 40px;
  width: 120px;
  border-radius: 45px;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  color: #9b9b9b;
  text-transform: capitalize;
  margin-right: 20px;
}
.custom-date-dialog .custom-date-select-cancel:focus {
  outline: none;
}
.custom-date-dialog .custom-date-select-cancel:hover {
  background-color: #ffffff;
  color: #9b9b9b;
}
.custom-date-dialog .custom-date-select-done {
  height: 40px;
  width: 120px;
  border-radius: 45px;
  font-size: 16px;
  line-height: 24px;
  background-color: #4a90e2;
  color: #ffffff;
  text-transform: capitalize;
}
.custom-date-dialog .custom-date-select-done:focus {
  outline: none;
}
.custom-date-dialog .custom-date-select-done:hover {
  background-color: #4a90e2;
  color: #ffffff;
}

.picky__input {
  height: 30px;
  border: 1px solid #cfd3d4;
  border-radius: 3px;
  padding: 1px 7px 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.picky__input::after {
  top: 13px;
}

.picky__dropdown {
  border: 1px solid #cfd3d4;
}

.picky__input,
.picky__dropdown,
.option {
  font-size: 14px;
}

.picky__dropdown .option:focus,
.picky__dropdown li:focus {
  outline: none !important;
}

