#guest-summary-root {
  width: 100%;
  height: 100%;
}
#guest-summary-root .guest-summary-wrapper {
  width: 75%;
  margin: 0 auto;
  padding: 25px 0;
}
@media (max-width: 767px) {
  #guest-summary-root .guest-summary-wrapper {
    width: 90%;
  }
}
#guest-summary-root .guest-summary-header {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}
#guest-summary-root .guest-summary-header i {
  color: #424c9f;
  font-size: 18px;
}
#guest-summary-root .guest-summary-header p {
  color: #4a4a4a;
  font-size: 24px;
  margin-bottom: 0;
}
#guest-summary-root .guest-summary-content {
  width: 100%;
  height: 90%;
  padding-left: 36px;
}
@media (max-width: 767px) {
  #guest-summary-root .guest-summary-content {
    padding-left: 0;
  }
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  #guest-summary-root .guest-summary-content .guest-summary-content-wrapper {
    flex-direction: column;
  }
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper {
  width: 58.5%;
}
@media (max-width: 767px) {
  #guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper {
    width: 100%;
  }
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .name-wrapper {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  padding: 10px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .name-wrapper h5 {
  color: #4a4a4a;
  font-size: 16px;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: 5px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .name-wrapper h5:first-child span {
  font-weight: bold;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .name-wrapper .address-textarea {
  width: 100%;
  color: #4a4a4a;
  font-size: 16px;
  border: none;
  line-height: 24px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .name-wrapper .address-textarea:focus-visible {
  outline: none;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper {
  padding-top: 20px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .sku-list-wrapper {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .sku-list-wrapper .title {
  color: #4a4a4a;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: bold;
  padding: 20px 10px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper {
  padding: 10px 10px 20px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper:last-child {
  border-bottom: none;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper .gsummary-code-name-wrapper {
  display: flex;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper .gsummary-code-name-wrapper p:first-child {
  width: 80%;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper .gsummary-code-name-wrapper p:last-child {
  width: 20%;
  text-align: right;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper p {
  font-size: 16px;
  color: #4a4a4a;
  margin-bottom: 0;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper .gsummary-price-wrapper i {
  font-size: 18px;
  color: #fa9917;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .sku-list-title-wrapper .gsummary-catalog-item-wrapper .price {
  font-weight: bold;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .button-wrapper {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .button-wrapper .form-update-button {
  height: 45px;
  width: 100%;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: #f3ca3e;
  color: #4a4a4a;
  text-transform: capitalize;
  font-weight: bold;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .button-wrapper .form-update-button:focus {
  outline: none;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .button-wrapper .form-update-button:hover {
  background-color: #f3ca3e;
  color: #4a4a4a;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .button-wrapper .disabled-button {
  height: 45px;
  width: 100%;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  text-transform: capitalize;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .button-wrapper .disabled-button:focus {
  outline: none;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .name-sku-list-wrapper .button-wrapper .disabled-button:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root {
  width: 38.5%;
}
@media (max-width: 767px) {
  #guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root {
    width: 100%;
  }
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .price-details-wrapper {
  padding: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .price-details-wrapper .details-title {
  color: #989898;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .price-details-wrapper p {
  color: #4a4a4a;
  margin-bottom: 0;
  font-weight: bold;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .price-details-wrapper .account-details-wrapper .value-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .price-details-wrapper .account-details-wrapper .value-wrapper:last-child {
  padding-top: 20px;
  padding-bottom: 0px;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .price-details-wrapper .account-details-wrapper .value-wrapper:last-child h5 {
  font-weight: bold;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .price-details-wrapper .account-details-wrapper h5 {
  color: #4a4a4a;
  font-size: 16px;
  margin-bottom: 0;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .button-wrapper {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .button-wrapper .form-update-button {
  height: 45px;
  width: 100%;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: #00acf1;
  color: #4a4a4a;
  text-transform: capitalize;
  font-weight: bold;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .button-wrapper .form-update-button:focus {
  outline: none;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .button-wrapper .form-update-button:hover {
  background-color: #00acf1;
  color: #4a4a4a;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .button-wrapper .disabled-button {
  height: 45px;
  width: 100%;
  border-radius: 25px;
  font-size: 18px;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  text-transform: capitalize;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .button-wrapper .disabled-button:focus {
  outline: none;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .button-wrapper .disabled-button:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .support-wrapper p {
  color: #4a4a4a;
  font-size: 16px;
  margin-bottom: 0;
}
#guest-summary-root .guest-summary-content .guest-summary-content-wrapper .price-details-root .support-wrapper p a {
  color: #00acf1;
}
#guest-summary-root .guest-success-wrapper {
  width: 100%;
  height: 100%;
}
#guest-summary-root .guest-success-wrapper .guest-success-content-wrapper {
  width: 60%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
}
@media (max-width: 767px) {
  #guest-summary-root .guest-success-wrapper .guest-success-content-wrapper {
    width: 90%;
  }
}
#guest-summary-root .guest-success-wrapper .guest-success-content-wrapper .circle-check-icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #2ac940;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#guest-summary-root .guest-success-wrapper .guest-success-content-wrapper .circle-check-icon i {
  font-size: 25px;
  color: #fff;
}
#guest-summary-root .guest-success-wrapper .guest-success-content-wrapper p {
  color: #4a4a4a;
}
#guest-summary-root .guest-success-wrapper .guest-success-content-wrapper .order-successfull {
  font-size: 20px;
  font-weight: bold;
}
#guest-summary-root .guest-success-wrapper .guest-success-content-wrapper .order-no {
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
  color: #4a4a4a;
}
#guest-summary-root .guest-success-wrapper .guest-success-content-wrapper .order-email {
  margin-top: 20px;
}
#guest-summary-root .irctc-user-logo-wrapper {
  margin-bottom: 30px;
}
#guest-summary-root .irctc-user-logo-wrapper .logo {
  width: 250px;
}
@media (min-width: 1024px) {
  #guest-summary-root .irctc-user-logo-wrapper .logo {
    width: 280px;
  }
}

