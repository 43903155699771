@import './../../../../scss/mixins';

#otp-modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .otp-modal-container {
    width: 50%;
    height: 30%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    @include smdevice {
      width: 90%;
      min-height: 300px;
    }
    .otp-modal-header {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-bottom: 2px solid black;
      p {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        margin-bottom: 0;
      }
      .close-button {
        color: #00acf1;
        font-family: 'Font Awesome 5 Pro';
        font-size: 20px;
        font-weight: 300;
        position: absolute;
        right: 2%;
        cursor: pointer;
      }
    }
    .otp-modal-body {
      width: 100%;
      height: calc(100% - 50px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .otp-modal-content {
        width: 60%;
        @include smdevice {
          width: 80%;
        }
        input {
          box-shadow: none;
        }
        .login-input-title {
          color: #9b9b9b;
          font-size: 14px;
          line-height: 16px;
          padding: 10px 0;
          .active {
            color: #00acf1;
          }
        }

        .login-input-title > span {
          padding: 2px;
          cursor: pointer;
        }

        .login-input-title > span:nth-child(1),
        .login-input-title > span:nth-child(3) {
          text-decoration: underline;
        }
        .input-field {
          position: relative;
          z-index: 4;
          border: none;
          // padding-bottom: 20px;
        }
        .under-line {
          height: 2px;
          width: 100%;
          border-radius: 2px;
          background: linear-gradient(270deg, #03f597 0%, #0081ff 100%);
        }
        .submit-button {
          width: 100%;
          height: 49px;
          margin-top: 20px;
          margin-bottom: 10px;
          text-align: end;
          background: linear-gradient(270deg, #008fc4 0%, #026489 100%);
          font-size: 15px;
        }
        .paddingLeft-20 {
          padding-left: 20px;
        }
        .react-tel-input {
          .form-control {
            width: 100%;
          }
        }
      }
    }
  }
}
