#image-preview-modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .image-preview-modal-container {
    width: 50%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    .image-preview-modal-header {
      min-height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-bottom: 2px solid black;
      p {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        margin-bottom: 0;
      }
      .close-button {
        color: #00acf1;
        font-family: 'Font Awesome 5 Pro';
        font-size: 20px;
        font-weight: 300;
        position: absolute;
        right: 2%;
        cursor: pointer;
      }
    }
    .image-preview-modal-body {
      width: 100%;
      // height: 90%;
      overflow: auto;
      padding: 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
.image-gallery-content.fullscreen {
  background: #fff !important;
}
