.lookup-modal-root {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lookup-modal-root.select-root {
  height: 90vh;
}
.lookup-modal-root #lookup-modal-wrapper {
  width: 95%;
  height: 95%;
  background-color: #e5eaef;
  border-radius: 3px;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper {
  height: 6%;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper.select-title-wrapper {
  height: 10%;
  margin-top: 0;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper.lookup-new-wrapper {
  flex-direction: column;
  margin-bottom: 20px;
  align-items: unset;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper.lookup-new-wrapper.user-directory-title-wrapper {
  height: 10%;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper.lookup-new-wrapper .lookup-new-close-icon {
  text-align: right;
  padding-bottom: 20px;
  margin-right: 30px;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper.lookup-new-wrapper .lookup-new-close-icon.user-directory-title-close-icon {
  padding-bottom: 0;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper.lookup-new-wrapper .lookup-new-close-icon i {
  font-size: 20px;
  color: #424c9f;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper.lookup-new-wrapper .lookup-new-title-search-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper .title {
  height: 40px;
  border-radius: 0 20px 20px 0;
  background-color: #6471de;
  padding: 0 10px 0 30px;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper .title p {
  height: 40px;
  line-height: 40px;
  max-width: 300px;
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper .search-filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-title-wrapper .search-filter-wrapper .cancel-icon {
  font-size: 18px;
  padding: 0 30px 0 20px;
  color: #424c9f;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-content-wrapper {
  padding: 0 30px 10px 30px;
  height: 74%;
  overflow-y: auto;
}
.lookup-modal-root #lookup-modal-wrapper .lookup-modal-content-wrapper .lookup-options-value {
  height: 45px;
  font-size: 16px;
  padding-left: 5px;
  margin-bottom: 10px;
  border: 1px solid #979797;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
.lookup-modal-root #lookup-modal-wrapper .tabel-footer button:focus {
  outline: none;
}
.lookup-modal-root .lookup-data-spinner {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checklist-modal-title-wrapper {
  height: 6%;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checklist-modal-title-wrapper.select-footer-wrapper {
  height: 7%;
  align-items: flex-end;
  margin-top: 0;
}
.checklist-modal-title-wrapper .title {
  width: 100%;
  height: 40px;
  background-color: #00acf1;
  padding: 0 10px 0 30px;
}
.checklist-modal-title-wrapper .title p {
  height: 40px;
  line-height: 40px;
  width: 100%;
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.add-button-wrapper .add-button {
  height: 40px;
  width: 100%;
  border-radius: 0;
  font-size: 24px;
  line-height: 0;
  background-color: #00acf1;
  color: #ffffff;
  text-transform: capitalize;
}
.add-button-wrapper .add-button:focus {
  outline: none;
}
.add-button-wrapper .add-button:hover {
  background-color: #00acf1;
  color: #ffffff;
}
.add-button-wrapper .add-button-disabled {
  height: 40px;
  width: 100%;
  border-radius: 0;
  font-size: 24px;
  line-height: 0;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  text-transform: capitalize;
}
.add-button-wrapper .add-button-disabled:focus {
  outline: none;
}
.add-button-wrapper .add-button-disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.no-records-found {
  width: 100%;
  color: #4a4a4a;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
}

