$smdevice-width: 767px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin button(
  $width,
  $height,
  $borderRadius,
  $fontSize,
  $lineHeight,
  $backgroundColor,
  $color
) {
  height: $height;
  width: $width;
  border-radius: $borderRadius;
  font-size: $fontSize;
  // font-weight: bold;
  line-height: $lineHeight;
  background-color: $backgroundColor;
  color: $color;
  text-transform: capitalize;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $backgroundColor;
    color: $color;
  }
}

@mixin line-clamp($height, $lineHeight, $numLines: 2) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  line-height: $lineHeight;
  height: $height;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
}

@mixin smdevice {
  @media (max-width: #{$smdevice-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

%disFlex {
  @include desktop {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
  @include tablet {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
}
