.user-id-card-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.user-id-card-container h2 {
  padding: 30px 0;
}
@media (max-width: 767px) {
  .user-id-card-container h2 {
    font-size: 25px;
  }
}
.user-id-card-container p {
  color: red;
}
.user-id-card-container .company-logo {
  padding: 40px 0;
}
.user-id-card-container .company-logo img {
  width: 200px;
}
@media (min-width: 1024px) {
  .user-id-card-container .company-logo img {
    width: 250px;
  }
}

