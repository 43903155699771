@import './../../../scss/mixins';
#mo-footer {
  width: 100%;
  height: auto;
  background-color: #595f66;
  position: relative;
  padding: 20px 0;
  font-family: Helvetica !important;
  -webkit-font-smoothing: auto !important;
  @include smdevice {
    height: auto;
  }
  @include tablet {
    height: auto;
  }
  @media print {
    display: none;
  }
  .container {
    .logo-img {
      width: 99px;
      height: 25px;
    }
    a + p {
      color: #ffffff;
      font-size: 14px;
      letter-spacing: -0.21px;
      line-height: 16px;
      margin-top: 30px;
    }
    li {
      list-style: none;
    }
    .play-google {
      width: auto;
      padding: 0;
      cursor: pointer;
      @extend %disFlex;
      @include smdevice {
        width: 100%;
        position: relative;
        left: 0;
        top: 0;
        display: flex;
      }
      li {
        &:first-child img {
          margin-left: -12px;
          @include smdevice {
            margin-left: 0;
          }
        }
        &:last-child img {
          width: 153px;
          margin: 11px 0 0 0;
          @include smdevice {
            width: 123px;
          }
        }
        img {
          width: 190px;
          @include smdevice {
            width: 160px;
          }
        }
      }
    }
    .icon-holder {
      @extend %disFlex;
      align-items: flex-end;
      @include smdevice {
        display: flex;
      }
      p {
        margin: 0 20px 0 0;
        color: #ffffff;
        font-size: 14px;
        letter-spacing: -0.1px;
        @include smdevice {
          font-size: 13px;
          margin: 0 20px 0 0;
        }
        @include tablet {
          margin: 0 20px 0 0;
        }
      }
      i {
        color: #ffffff;
        padding: 0 10px 0 0;
        font-size: 32px;
        cursor: pointer;
        @include smdevice {
          font-size: 22px;
        }
      }
    }
    .company-info {
      @extend %disFlex;
      justify-content: flex-end;
      a {
        color: #ffffff;
      }
      @include smdevice {
        display: flex;
        justify-content: flex-start;
        padding-top: 10px;
        ul {
          &:first-child {
            padding-inline-start: 0;
          }
        }
      }
      li {
        color: #ffffff;
        font-size: 12px;
        letter-spacing: -0.21px;
        line-height: 26px;
        cursor: pointer;
        &:first-child {
          font-size: 20px;
          cursor: text;
          @include smdevice {
            font-size: 17px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
