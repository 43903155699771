@import './../../../scss/mixins';

#home-root {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 26px;
  overflow-y: auto;

  .apps-wrapper {
    width: 70%;

    .app-cards-root {
      height: 90%;

      // overflow-y: auto;
      .app-cards-wrapper {
        display: flex;
        flex-wrap: wrap;

        // overflow-y: auto;
        .app-card-wrapper {
          width: 175px;
          height: 125px;
          margin: 5px 35px 40px 5px;
          border-radius: 3px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
          position: relative;
          cursor: pointer;

          h6 {
            width: 120px;
            height: 61px;
            font-size: 20px;
            text-align: center;
            line-height: 20px;
            color: #3a507e;
            padding-top: 20px;
            overflow: hidden;
            margin: 0 auto;
            cursor: pointer;
            @include line-clamp(61px, 20px);
          }

          .rectangle-5 {
            cursor: pointer;

            &::before {
              content: '';
              width: 100%;
              height: 24px;
              background-color: #ffffff;
              position: absolute;
              left: 0;
              top: -30px;
              transform: skew(-40deg) scale(2.1) rotateZ(-4deg);
            }

            height: 40px;
            width: 175px;
            border-radius: 0 0 3px 3px;
            background-color: #00acf1;
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            overflow: hidden;
            justify-content: space-between;

            p {
              margin: 0;
              font-size: 10px;
              color: #ffffff;
              padding-left: 15px;
              padding-top: 10px;
            }

            i {
              font-size: 15px;
              color: #ffffff;
              padding-right: 10px;
              padding-top: 10px;
            }
          }

          .selected-wrapper {
            background: #00acf1;
            height: 10px;
            width: 20px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            position: absolute;
            bottom: -10px;
            right: 5px;

            .selected-workflow-icon {
              color: #ffffff;
              position: relative;
              top: -7px;
              right: -5px;
            }
          }
        }
      }
    }
  }

  .header {
    height: 10%;
    display: flex;
    align-items: center;

    &.task-header {
      width: 95%;
      margin: 0 auto;
    }

    h5 {
      font-size: 25px;
      color: #4a4a4a;
    }
  }

  #updates-wrapper {
    width: 30%;
    background-color: #e5eaef;

    .no-pending-tasks {
      width: 95%;
      margin: 0 auto;

    }

    .task-content-wrapper {
      background-color: #e5eaef;
      padding-bottom: 1px;
    }

    .tabel-footer {
      background-color: #e5eaef;
    }

    .update-card-wrapper {
      width: 95%;
      margin: 0 auto 15px;
      padding: 10px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;

      .task-title {
        padding-top: 5px;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .date-time-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .date-time-card {
        height: 35px;
        padding: 5px;
        border-radius: 10px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
      }

      .date-time-icon {
        color: #00acf1;
        padding-right: 5px;
      }

      .date-card {
        width: 100px;
        margin-right: 15px;
      }

      .time-card {
        width: 90px;
      }
    }

  }
}